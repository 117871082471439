@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Amalfi-Coast";
  src: url(../assets/fonts/Amalfi-Coast.ttf);
}
@font-face {
  font-family: "Montserrat-Black";
  src: url(../assets/fonts/Montserrat-Black.ttf);
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url(../assets/fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: "Montserrat-Light";
  src: url(../assets/fonts/Montserrat-Light.ttf);
}
@font-face {
  font-family: "Montserrat-Medium";
  src: url(../assets/fonts/Montserrat-Medium.ttf);
}
@font-face {
  font-family: "Montserrat-Thin";
  src: url(../assets/fonts/Montserrat-Thin.ttf);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat-Light", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.family-amalfi {
  font-family: "Amalfi-Coast" !important;
}
.family-black {
  font-family: "Montserrat-Black" !important;
}
.family-bold {
  font-family: "Montserrat-Bold" !important;
}
.family-light {
  font-family: "Montserrat-Light" !important;
}
.family-medium {
  font-family: "Montserrat-Medium" !important;
}
.family-thin {
  font-family: "Montserrat-Thin" !important;
}


.font-white {
  color: #ffffff !important;
}
.font-black {
  color: #000000 !important;
}
.font-pink {
  color: #b40a7f !important;
}
.font-blue {
  color: #45506e !important;
}
.font-cream {
  color: #d7e7bd !important;
}
.font-gray2 {
  color: #4d4d4e !important;
}
.font-gray {
  color: #aeaeb3 !important;
}


.font-xs {
  font-size: 0.75rem;
}
.font-small {
  font-size: 0.875rem;
}
.font-normal {
  font-size: 1rem;
}
.font-medium {
  font-size: 1.2rem;
}
.font-regular {
  font-size: 1.6rem;
}
.font-lg {
  font-size: 2rem;
}
.font-big {
  font-size: 3rem;
}


.back-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.back-top svg {
  color: #1a1a1a !important;
}

.box-number {
  background: rgba(255, 255, 255, 0);
  animation-name: box-show;
  animation-duration: 2s;
}



.bg-diferencial {
  background: url(../assets/background/bg-diferencial.jpeg) center center no-repeat;
  background-size: cover;
}
.bg-exclusivo {
  background: url(../assets/background/bg-exclusivo.jpeg) center no-repeat;
  background-size: cover;
}
.bg-familia {
  background: url(../assets/background/planta-diferencial.jpeg) top center no-repeat;
  background-size: cover;
}
.bg-fotos {
  background: url(../assets/background/bg-fotos.jpeg) bottom no-repeat;
  background-size: cover;
}
.bg-black {
  background-color: #000000;
}
.bg-white {
  background-color: #ffffff;
}


.galeria {
  background: url(../assets/file/Galeria.png) top center no-repeat;
  background-size: cover;
}


.bg-topo {
  background: url(../assets/background/bg-topo.jpeg) center center no-repeat;
  background-size: cover; 
  padding-top: 40px;
  padding-bottom: 85px;
}
.topo-text {
  font-size: 1.5em;  
  color: white;
  text-align: center;
  justify-content: center;
  align-items: flex-start;
  font-family: Montserrat-Medium;
  text-transform: uppercase;
  top: -1px;
  right: 690px
} 
.descricao1-img {
  width: 440px;
}  
.descricao2-img {
 /* width: 800px; */
 padding-left: 374px;
 padding-top: 250px;
} 
.descricao3-img {
/* width: 1000px; */
padding-left: 678px;
padding-top: 50px;
}
.descricao-box {
  width: auto;
  padding-left: 1030px;
  padding-top: 30px;
  }
.diferencial-box {
  align-items: center;
  justify-content: center;
  width: 7rem;
}
.diferencial-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.diferencial-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.diferencial-text {
  font-size: 1.25em;  
  color: black;
  text-align: center;
  justify-items: center;
  justify-content: center;
  font-family: Montserrat-Bold;
  padding-left: 40px;
  padding-right: 40px;
}

.fotos-text {
  font-size: 2.5em;  
  color: black;
  text-align: center;
  font-family: Montserrat-Medium;
}

.familia-title {
  font-size: 3em;  
  color: #b40a7f;
  text-align: center;
  text-transform: uppercase;
  font-family: Montserrat-Medium;
}
.familia-text {
  font-size: 2em;  
  color: black;
  text-align: center;
  font-family: Montserrat-Medium;
}
.rodape-title {
  font-size: 1em;  
  color: white;
  text-align: center;
  font-family: Montserrat-Medium;
}
.rodape-text {
  font-size: 0.75em;  
  color: white;
  text-align: center;
  font-family: Montserrat-Light;
}
.box-planta {
  background-color: #f0f0f0;
  border-radius: 3rem;
  line-height: 100%;
  box-shadow: 0 25px 50px 10px rgb(0 0 0 / 0.25);
}

.planta-info {
  font-size: 2em;
}
.planta-attention strong{
  font-family: "Montserrat-Bold" !important;
}
.planta-attention {
  font-size: 1.4em;
}

.mapa-visite {
  font-size: 2em;
  line-height: 120%;
  color: white;
  text-transform: uppercase;
  font-family: "Montserrat-Bold";
  bottom: 120px;
}
.mapa-whats {
  font-size: 1.8em;
  line-height: 120%;
  color: white;
  font-family: "Montserrat-Bold";
  bottom: 80px;
}
.mapa-title {
  font-size: 4em;
  text-transform: uppercase;
  color: white;
  font-family: "Montserrat-Medium";
}
.mapa-desc {
  font-size: 1.8em;
}
.mapa-phone {
  font-size: 2.4em;
}
.mapa-address {
  font-size: 1.5em;
  line-height: 100%;
  color: black;
  font-family: "Montserrat-Bold";
}
.bg-mapa {
  background: url(../assets/static/mapa.png) center no-repeat;
  width: 100vw;
}
.button69 { 
  width: 260px;
  height: 70px;
  border-radius: 0.5rem; 
  border-color: #a40372;
  background-color: #a40372;
  color: white;
  font-size: 1.2em;
  font-family: "Montserrat-Medium";
}
.button82 { 
  width: 260px;
  height: 70px;
  border-radius: 0.5rem; 
  border-color: #bd2d90;
  background-color: #bd2d90;
  color: white;
  font-size: 1.2em;
  font-family: "Montserrat-Medium";
}
.buttonC { 
  width: 260px;
  height: 70px;
  border-radius: 0.5rem; 
  border-color: #bd2d90;
  background-color: #bd2d90;
  color: white;
  font-size: 1.2em;
  font-family: "Montserrat-Medium";
}
.buttonAB { 
  width: 260px;
  height: 70px;
  border-radius: 0.5rem; 
  border-color: #bd2d90;
  background-color: #bd2d90;
  color: white;
  font-size: 1.2em;
  font-family: "Montserrat-Medium";
}

.button69:active {
  background-color: #bd2d90;
}
.button82:active {
  background-color: #a40372;
}
.buttonC:active {
  background-color: #a40372;
}
.buttonAB:active {
  background-color: #a40372;
}

.saiba-title {
  font-size: 4em;  
  color: #a40372;
  font-family: Amalfi-Coast;
}
.saiba-text {
  font-size: 1.5em;  
  color: black;
  font-family: Montserrat-Medium;
}
.exclusivo-title {
  font-size: 1.5em;  
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-family: Montserrat-Medium;
}
.exclusivo-text {
  font-size: 1.25em;  
  color: white;
  text-align: center;
  font-family: Montserrat-Medium;
}
.imgMac {
  width: 100%;
  height: auto;
}
.title {
  font-family: Montserrat-Light;
  border-left: 6px solid #b40a7f;
  padding-left: 24px;
}

.title-pink strong {
  font-family: Montserrat-Bold;
  color: #b40a7f;
  font-size: 1.8rem;
  margin-right: 1rem;
}
.line-height {
  line-height: 2rem;
}
.button-cadastro {
  background-color: #b40a7f;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1em;
}
.button-retornar {
  background-color: #b40a7f;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.25em;
}
.bars-tecnology {
  animation-name: slidein;
}

.btn-black:hover {
  background: #1a1a1a;
}
.btn-pink {
  background: #b40a7f;
  width: 350px;
}
.btn-pink:hover {
  background: #b40a7f;
}
.tec_dir {
  border-top-left-radius: 16px;
}
.tec_left {
  border-bottom-right-radius: 16px;
}
.strong {
  font-family: Montserrat-Light;
}
.formulario {
  background: none;
  border: 2px solid #b40a7f;
  border-radius: 8px;
  padding-right: 200px;
}
.whats-message {
  z-index: 9;
}

.w3-modal-content {
  border-radius: 2em;
}

.w3-container {
  padding: 2em 2em 0.8em;
}

.modal-cancel {
    background-image: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    background-color: #EEEEEE;
    border-color: #ECECEC;
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    color: #CCCCCC;
    height: 42px;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0em;
    line-height: 1;
    text-transform: capitalize;
    margin-bottom: 10px;
    margin-top: 0;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.splide__pagination__page.is-active {
  background-color: #b40a7f !important;
}

.splide__arrow svg {
  fill: black !important;
}

@media (max-width: 2160px) {
  .bg-topo {
    background: url(../assets/background/bg-topo.jpeg) center center no-repeat;
    background-size: cover; 
    padding-top: 100px;
    padding-bottom: 85px;
  }
}
@media (max-width: 1380px) {
  .bg-topo {
    background: url(../assets/background/bg-topo.jpeg) center center no-repeat;
    background-size: cover; 
    padding-top: 30px;
    padding-bottom: 85px;
  }
}
@media (max-width: 991px) {
  
}
@media (max-width: 767px) {
  .bg-topo {
    background: url(../assets/background/bg-topo.jpeg) bottom left no-repeat;
    background-size: cover; 
    height: 730px;  
  }
  .topo-text {
    position: relative;
    font-size: 1em;  
    color: white;
    text-align: center;
    align-content: center;
    font-family: Montserrat-Medium;
    text-transform: uppercase;
    top: -130px;
  } 
  .mapa-visite {
    font-size: 1.8em;
    line-height: 120%;
    color: white;
    text-transform: uppercase;
    font-family: "Montserrat-Bold";
    bottom: 75px;
  }
  .mapa-whats {
    font-size: 1.5em;
    line-height: 120%;
    color: white;
    font-family: "Montserrat-Bold";
    bottom: 40px;
  }
  .mapa-title {
    font-size: 4em;
    text-transform: uppercase;
    color: white;
    font-family: "Montserrat-Medium";
  }
  .mapa-desc {
    font-size: 1.8em;
  }
  .mapa-phone {
    font-size: 2.4em;
  }
  .mapa-address {
    font-size: 1.2em;
    line-height: 100%;
    color: black;
    font-family: "Montserrat-Bold";
  }
  .button { 
    width: 200px;
    height: 50px;
    border-radius: 0.5rem; 
    border-color: #bd2d90;
    background-color: #bd2d90;
    color: white;
    font-size: 1.2em;
    font-family: "Montserrat-Medium";
    
  }
  .box-mobile {
    background-color: #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0 25px 50px 10px rgb(0 0 0 / 0.25);
  }
}
@media (max-width: 575px) {
  .font-big {
    font-size: 2.4rem;
  }
  .btn-black {
    width: 100%;
  }
  .btn-pink {
    width: 100%;
  }
   
  .bg-familia {
    background-color: #f5f5f2f8;
    background: url(../assets/background/bg-familia.jpeg) top center no-repeat;
    background-size: cover;
  }
  .bg-fotos {
    background: url(../assets/background/bg-fotos.jpeg) bottom center no-repeat;
    background-size: cover;
  }
  .bg-mapa {
    background: url(../assets/background/bg-mapa.jpeg) center no-repeat;
    background-size: cover;
  }
  .bg-black {
    background-color: #000000;
  }
  .bg-white {
    background-color: #ffffff;
  }
  
  
  .diferencial-box {
    align-items: center;
    justify-content: center;
    width: 4rem;;
  }
  .diferencial-item {
    display: row;
    flex-direction: column;
  }
  .diferencial-content {
    display: flex;
    flex-direction: column;
  }
  .diferencial-text {
    font-size: 1em;  
    color: black;
    font-family: Montserrat-Bold;
  }
   
  .fotos-text {
    font-size: 1.8em;  
    color: black;
    text-align: center;
    font-family: Montserrat-Medium;
  }
  .familia-title {
    font-size: 1.5em;  
    color: #b40a7f;
    text-align: center;
    text-transform: uppercase;
    font-family: Montserrat-Medium;
  }
  .familia-text {
    font-size: 1.25em;  
    color: black;
    text-align: center;
    font-family: Montserrat-Medium;
  }
  .box-planta {
    background-color: #f0f0f0;
    border-radius: 2rem;
    line-height: 80%;
    box-shadow: 0 25px 50px -1px rgb(0 0 0 / 0.25);
  }
  .box-botao {
    border-width: 0.5px;
    border-color: transparent;
    border-top-width: 1px;
  }
  .topo-text {
    font-size: 1em;  
    color: white;
    text-align: center;
    justify-items: center;
    justify-content: center;
    font-family: Montserrat-Medium;
    text-transform: uppercase;
  } 
  .mapa-address {
    font-size: 1em;
    line-height: 120%;
    color: black;
  }
  .mapa-visite {
    font-size: 1.25em;
    line-height: 120%;
    color: white;
    text-transform: uppercase;
    font-family: "Montserrat-Bold";
    bottom: 60px;
  }
  .mapa-whats {
    font-size: 1em;
    line-height: 120%;
    color: white;
    font-family: "Montserrat-Bold";
    bottom: 35px;
  }
  .button { 
    width: 200px;
    height: 50px;
    border-radius: 0.5rem; 
    border-color: #bd2d90;
    background-color: #bd2d90;
    color: white;
    font-size: 1.2em;
    font-family: "Montserrat-Medium";
  }
  .box-mobile {
    background-color: #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0 25px 50px 10px rgb(0 0 0 / 0.25);
  }
  .bg-topo {
    background: url(../assets/background/bg-topo.jpeg) left center no-repeat;
    background-size: cover;    
  }
}
  @media (max-width: 420px) {
    .mapa-address {
      font-size: 0.8em;
      line-height: 100%;
      color: black;
      font-family: "Montserrat-Bold";
    }
    .mapa-title {
      font-size: 2em;
      text-transform: uppercase;
      color: #b40a7f;
      font-family: "Montserrat-Medium";
    }
    .mapa-visite {
      font-size: 1em;
      line-height: 120%;
      color: white;
      text-transform: uppercase;
      font-family: "Montserrat-Bold";
      bottom: 45px;
      text-align: center;
    }
    .mapa-whats {
      font-size: 0.75em;
      line-height: 120%;
      color: white;
      font-family: "Montserrat-Bold";
      bottom: 30px;
      text-align: center;
    }
    .bg-topo {
      background: url(../assets/background/bg-topo.jpeg) left center no-repeat;
      background-size: cover;    
      height: 100vh;
    }
    .top-img {
      margin-top: -50px;
    }
    
    .fotos-text {
      font-size: 1.5em;  
      color: black;
      text-align: center;
      font-family: Montserrat-Medium;
    }
    .familia-title {
      font-size: 1.2em;  
      color: #b40a7f;
      text-align: center;
      text-transform: uppercase;
      font-family: Montserrat-Medium;
    }
    .familia-text {
      font-size: 1.2em;  
      color: black;
      text-align: center;
      font-family: Montserrat-Medium;
    }
    .exclusivo-title {
      font-size: 1.2em;  
      color: white;
      text-align: center;
      text-transform: uppercase;
      font-family: Montserrat-Medium;
    }
    .exclusivo-text {
      font-size: 1em;  
      color: white;
      text-align: center;
      font-family: Montserrat-Medium;
    }
    .rodape-title {
      font-size: 0.8em;  
      color: white;
      text-align: center;
      font-family: Montserrat-Light;
    }
    .rodape-text {
      font-size: 0.5em;  
      color: white;
      text-align: center;
      font-family: Montserrat-Light;
    }
    .topo-text {
      font-size: 1em;  
      color: white;
      text-align: center;
      justify-items: center;
      justify-content: center;
      font-family: Montserrat-Medium;
      text-transform: uppercase;
      left: 5px;
    } 
    .button { 
      width: 200px;
      height: 50px;
      border-radius: 0.5rem; 
      border-color: #bd2d90;
      background-color: #bd2d90;
      color: white;
      font-size: 1.2em;
      font-family: "Montserrat-Medium";
    }
    .box-mobile {
      background-color: #ffffff;
      border-radius: 0.75rem;
      box-shadow: 0 25px 50px 10px rgb(0 0 0 / 0.25);
    }
    .saiba-title {
      font-size: 2.5em;  
      color: #a40372;
      font-family: Amalfi-Coast;
    }
    .saiba-text {
      font-size: 1.1em;  
      color: black;
      font-family: Montserrat-Medium;
    }
}

/* SCROLLBAR */
@media only screen and (min-width: 600px) {
  * {
    scrollbar-width: thin;
    scrollbar-color: #b40a7f;
  }

  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #d7c7bd;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #b40a7f;
    border-radius: 20px;
    border: 3px solid #d7c7bd;
  }

  * {
    box-sizing: border-box;
  }
}
/* SCROLLBAR */
